





































































































































import {
  defineComponent,
  PropType,
  toRefs,
  ref,
  watch,
  computed,
  getCurrentInstance,
} from '@vue/composition-api'

import {
  customSelectOptionItem,
  itemHierarchyType,
  emitHierarchySearch,
  emitHierarchyRangeKeys,
  emitCustomSelectSelect,
} from '@/types/entities'

export default defineComponent({
  components: {},
  props: {
    itemLabel: {
      type: String as PropType<string>,
      default: '',
    },
    textLabel: {
      type: String as PropType<string>,
      default: '',
    },
    valueLabel: {
      type: String as PropType<string>,
      default: '',
    },
    data: {
      type: Array as PropType<customSelectOptionItem[]>,
      default: () => [],
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    itemHierarchyType: {
      type: String as PropType<itemHierarchyType>,
      default: '',
    },
    isSkipQuery: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    custumEmptyText: {
      type: String as PropType<string>,
      default: '',
    },
    enableRangeKeys: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    totalCount: {
      type: Number as PropType<number>,
      default: null,
    },
    first: {
      type: Number as PropType<number>,
      default: null,
    },
    selectMode: {
      type: String as PropType<string>,
      default: 'range',
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance()
    if (!instance) {
      console.log('should be called in setup')
      return
    }
    const { $i18n } = instance.proxy
    const emptyText = computed(() => {
      return props.custumEmptyText
        ? props.custumEmptyText
        : ($i18n.t('description.no-records') as string)
    })

    const selected = ref<customSelectOptionItem[]>([])
    const fields = ref([
      { key: 'selected', label: '', thStyle: { width: '1.5rem' } },
      { key: 'value', label: props.valueLabel },
      { key: 'text', label: props.textLabel },
    ])
    const searchWord = ref<string>('')
    const searchKey = ref<string>('')
    const fromKey = ref<string>('')
    const toKey = ref<string>('')
    // _selectPrevSelectedでprops.dataからフィルタしようとすると何故かうまくいかないため、
    // 一旦変数に格納している。
    const displayData = ref<customSelectOptionItem[]>([])
    // 全選択、全クリア中は選択済みの維持が働かないようにするためのフラグ
    const forceChanging = ref<boolean>(false)
    // dataが変わったときに選択がクリアされるため、前回data、選択状態を記録して再選択する
    const prevSelected = ref<string[]>([])
    // dataが変わったときにフラグをオンにする。選択の再設定したあとオフにする
    const changeDataFlg = ref<boolean>(false)

    const onRowSelected = (items: customSelectOptionItem[]) => {
      selected.value = items
    }
    const selectAllRows = () => {
      forceChanging.value = true
      // @ts-ignore
      instance.proxy.$refs.selectableTable.selectAllRows()
    }
    const clearSelected = () => {
      forceChanging.value = true
      // @ts-ignore
      instance.proxy.$refs.selectableTable.clearSelected()
    }
    const isCountOver = ref<boolean>(false)

    watch(selected, (newVal) => {
      // console.log(
      //   props.itemLabel,
      //   'watch selected',
      //   'forceChanging=',
      //   forceChanging.value,
      //   'changeDataFlg=',
      //   changeDataFlg.value,
      //   'prevSelected.value.length=',
      //   prevSelected.value.length
      // )
      const selectList = newVal.map(function (ele) {
        return ele.value
      })
      const keyList = newVal.map(function (ele) {
        return ele.key
      })
      const emitParam: emitCustomSelectSelect = {
        selectedCount: selectList.length,
        selectList: selectList,
        keyList: keyList,
      }
      if (!forceChanging.value && changeDataFlg.value) {
        console.log('do _selectPrevSelected')
        _selectPrevSelected()
        prevSelected.value = keyList
        changeDataFlg.value = false
      } else {
        prevSelected.value = keyList
        forceChanging.value = false
      }
      context.emit('change-selected', emitParam)
      // console.log(props.itemLabel, 'emit', { emitParam })
    })

    const onSearchWord = (value: string) => {
      const searchWord = value
      const params: emitHierarchySearch = {
        itemHierarchyType: props.itemHierarchyType,
        searchWord: searchWord,
        searchList: [],
      }
      context.emit('search-word', params)
    }

    const onSearch = (value: string) => {
      const searchList = value.split(/[,\s]+/)
      const params: emitHierarchySearch = {
        itemHierarchyType: props.itemHierarchyType,
        searchList: searchList,
        searchWord: '',
      }
      context.emit('search', params)
    }
    const onClicktemCountOverOk = () => {
      context.emit('count-over-ok', null)
    }
    const onChangeFromKey = (value: string) => {
      fromKey.value = value
      const params: emitHierarchyRangeKeys = {
        itemHierarchyType: props.itemHierarchyType,
        rangeKeys: {
          from: fromKey.value,
          to: toKey.value,
        },
      }
      context.emit('change-rangeKey', params)
    }
    const onChangeToKey = (value: string) => {
      toKey.value = value
      const params: emitHierarchyRangeKeys = {
        itemHierarchyType: props.itemHierarchyType,
        rangeKeys: { from: fromKey.value, to: toKey.value },
      }
      context.emit('change-rangeKey', params)
    }

    const { data, isSkipQuery } = toRefs(props)
    watch(data, (newVal) => {
      // console.log(props.itemLabel, 'watch data', newVal)
      // データがない場合は選択をクリアする
      if (newVal.length == 0) {
        clearSelected()
      }
      changeDataFlg.value = true
      // console.log(props.itemLabel, 'watch data', { data, isSkipQuery, newVal })
      if (isSkipQuery.value) {
        displayData.value = []
      } else {
        // @ts-ignore
        displayData.value = newVal
      }
    })
    watch(isSkipQuery, (newVal) => {
      // console.log(props.itemLabel, 'watch isSkipQuery', {
      //   data,
      //   isSkipQuery,
      //   newVal,
      // })
      if (newVal) {
        displayData.value = []
      } else {
        // @ts-ignore
        displayData.value = data.value
      }
    })
    const _selectPrevSelected = () => {
      // console.log('_selectPrevSelected', prevSelected.value)
      const selectTargetIndex: number[] = []
      displayData.value.forEach((ele: customSelectOptionItem, index) => {
        for (const pSelVal of prevSelected.value) {
          if (pSelVal == ele.key) {
            selectTargetIndex.push(index)
            break
          }
        }
      })
      // console.log({ selectTargetIndex })
      for (const index of selectTargetIndex) {
        // console.log('selectRow', index)
        // @ts-ignore
        instance.proxy.$refs.selectableTable.selectRow(index)
      }
    }

    return {
      isCountOver,
      selected,
      fields,
      searchWord,
      searchKey,
      fromKey,
      toKey,
      displayData,
      emptyText,
      onRowSelected,
      selectAllRows,
      clearSelected,
      onSearch,
      onSearchWord,
      onChangeFromKey,
      onChangeToKey,
      onClicktemCountOverOk,
    }
  },
  watch: {
    data(data) {
      if (this.first) {
        this.isCountOver = this.totalCount > this.first
      } else {
        this.isCountOver = false
      }
    },
  },
})
