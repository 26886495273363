























import {
  defineComponent,
  getCurrentInstance,
  ref,
  PropType,
} from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'

import CustomSelectTemplate from '@/components/organisms/h/item/hierarchy/CustomSelectTemplate.vue'
import {
  emitHierarchySelect,
  itemHierarchyType,
  emitHierarchySearch,
  emitHierarchyRangeKeys,
  emitCustomSelectSelect,
} from '@/types/entities'
import {
  UiMCategory1sDocument,
  UiMCategory1Edge,
  Where,
} from '@/types/generated/graphql'
import { MAX_COUNT } from '@/utils/constant'
import Toast, { genNotifyMessage } from '@/utils/toast'

export default defineComponent({
  components: { CustomSelectTemplate },
  props: {
    where: {
      type: Array as PropType<Where[]>,
      default: () => [],
    },
    itemHierarchyType: {
      type: String as PropType<itemHierarchyType>,
      default: '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  apollo: {
    uiMCategory1s: {
      query: UiMCategory1sDocument,
      variables() {
        return this.searchCond
      },
      skip: true,
      fetchPolicy: 'cache-and-network',
      error(e: ApolloError) {
        Toast.error(this, {
          message: genNotifyMessage('e.list', 'shobunrui', e),
        })
      },
    },
  },
  created() {
    this.$apollo.queries.uiMCategory1s.skip = false
    this.isSkipQuery = false
  },
  data() {
    return {
      uiMCategory1s: { edges: [] },
      // 表示を制御するためのフラグ（データを触ると想定しない挙動となるため用意した）
      isSkipQuery: true,
      totalCount: 0,
      first: MAX_COUNT,
    }
  },
  computed: {
    isLoading() {
      return this.$apollo.queries.uiMCategory1s.loading
    },
    shobunruiList() {
      return this.uiMCategory1s.edges.map((ele: UiMCategory1Edge) => {
        return {
          value: ele.node?.category1Cd,
          text: ele.node?.categoryName,
          key: ele.node?.category1Cd,
        }
      })
    },
    searchCond(props: any) {
      return {
        where: props.where,
        orderBy: [],
        first: this.first,
      }
    },
  },
  watch: {
    where(newVal) {
      this.resetFirst()
    },
    uiMCategory1s(data) {
      this.totalCount = this.uiMCategory1s.totalCount
    },
  },
  methods: {
    changeSelected(emitParams: emitCustomSelectSelect) {
      const setectedMetaInfo = this.uiMCategory1s.edges.filter(
        (ele: UiMCategory1Edge) => {
          const key = ele.node?.category1Cd
          if (key) {
            for (const selKey of emitParams.keyList) {
              if (selKey.indexOf(key) !== -1) {
                return true
              }
            }
          }
        }
      )
      const params: emitHierarchySelect = {
        itemHierarchyType: 'Shobunrui',
        selected: emitParams.selectList,
        selectedCount: emitParams.selectedCount,
        setectedMetaInfo: setectedMetaInfo,
      }
      this.$emit('change-selected', params)
    },
    onSearch(params: emitHierarchySearch) {
      this.$emit('search', params)
    },
    onSearchWord(params: emitHierarchySearch) {
      console.log('search-word')
      this.$emit('search-word', params)
    },
    changeRangeKey(params: emitHierarchyRangeKeys) {
      this.$emit('change-rangeKey', params)
    },
    countOverOk(params: any) {
      this.first = params
    },
    resetFirst() {
      this.first = MAX_COUNT
    },
  },
})
