














import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {},
  props: {
    requiredItemInput: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(_, context) {
    return {}
  },
})
