import { render, staticRenderFns } from "./ExtractChubunrui.vue?vue&type=template&id=db96166e&"
import script from "./ExtractChubunrui.vue?vue&type=script&lang=ts&"
export * from "./ExtractChubunrui.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports