

































import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {},
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    radioName: {
      type: String as PropType<string>,
      default: '',
    },
    useRadio: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, context) {
    const changeRadio = (selected: string) => {
      context.emit('change-radio', selected)
    }
    return { changeRadio }
  },
})
