






























import {
  defineComponent,
  getCurrentInstance,
  ref,
  PropType,
} from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'

import {
  emitHierarchySelect,
  itemHierarchyType,
  emitHierarchySearch,
  emitCustomSelectSelect,
} from '@/types/entities'
import {
  SaleItemsDocument,
  AllSaleitemMstEdge,
  Where,
} from '@/types/generated/graphql'
import { ITEM_STATUS } from '@/utils/constant'
import Toast, { genNotifyMessage } from '@/utils/toast'

export default defineComponent({
  components: {},
  props: {
    where: {
      type: Array as PropType<Where[]>,
      default: () => [],
    },
    itemHierarchyType: {
      type: String as PropType<itemHierarchyType>,
      default: '',
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    defaultItemsStatus: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.defaultItemsStatus,
    }
  },
  computed: {},
  watch: {
    selected(newVal) {
      // console.log('ExtractItemStatus', newVal)
      const params: emitHierarchySelect = {
        itemHierarchyType: 'ItemStatus',
        selected: newVal,
        selectedCount: newVal.length,
        setectedMetaInfo: null,
      }
      this.$emit('change-selected', params)
    },
  },
  methods: {
    getItemStatus(key: string) {
      // @ts-ignore
      return ITEM_STATUS[key]
    },
  },
})
