









































import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import { array, string } from 'prop-types'

import ExtractCategory from '@/components/organisms/h/item/hierarchy/ExtractCategory.vue'
import ExtractCausal from '@/components/organisms/h/item/hierarchy/ExtractCausal.vue'
import ExtractChubunrui from '@/components/organisms/h/item/hierarchy/ExtractChubunrui.vue'
import ExtractClass from '@/components/organisms/h/item/hierarchy/ExtractClass.vue'
import ExtractItem from '@/components/organisms/h/item/hierarchy/ExtractItem.vue'
import ExtractItemStatus from '@/components/organisms/h/item/hierarchy/ExtractItemStatus.vue'
import ExtractShobunrui from '@/components/organisms/h/item/hierarchy/ExtractShobunrui.vue'
import ExtractStore from '@/components/organisms/h/item/hierarchy/ExtractStore.vue'
import ExtractStoreShelve from '@/components/organisms/h/item/hierarchy/ExtractStoreShelve.vue'
import ExtractStoreSingle from '@/components/organisms/h/item/hierarchy/ExtractStoreSingle.vue'
import ExtractVendor from '@/components/organisms/h/item/hierarchy/ExtractVendor.vue'
import ExtractConditionCustomSelectLayout from '@/components/organisms/h/layout/ExtractConditionCustomSelectLayout.vue'
import ExtractConditionRowLayout from '@/components/organisms/h/layout/ExtractConditionRowLayout.vue'
import {
  itemHierarchyType,
  itemHierarchyUseList,
  emitHierarchySelect,
  emitHierarchySearch,
  emitHierarchyRangeKeys,
  rangeKeys,
  itemHierarchyGroupExtractCondition,
  dateGroupExtractCondition,
  dateRange,
} from '@/types/entities'
import {
  Where,
  WhereOp,
  Maybe,
  UiMStoreShelfEdge,
  AllStoreMstEdge,
  AllSaleitemMstEdge,
  UiMCategory0Edge,
  UiMCategory1Edge,
  UiMCategory2Edge,
  UiMCategory3Edge,
  VUiPCausalNameEdge,
} from '@/types/generated/graphql'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'

export default defineComponent({
  components: {
    ExtractConditionRowLayout,
    ExtractConditionCustomSelectLayout,
    ExtractItem,
    ExtractStore,
    ExtractStoreSingle,
    ExtractShobunrui,
    ExtractStoreShelve,
    ExtractChubunrui,
    ExtractCategory,
    ExtractClass,
    ExtractCausal,
    ExtractVendor,
    ExtractItemStatus,
  },
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    useList: {
      // 複数行指定できる
      // 例:
      //   [
      //    ['Shobunrui', 'StoreShelve'],
      //    ['Store']
      //   ]
      type: Array as PropType<itemHierarchyUseList>,
      default: () => [],
    },
    useRadio: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    radioName: {
      type: String as PropType<string>,
      default: '',
    },
    // 検索条件に使用する有効日
    validData: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
    defaultItemsStatus: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    areaType: {
      type: Array as PropType<string[]>,
      default: () => ['0', '1'],
    },
    notHierarchicalItem: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    nouseTanpin: {// 単品を使用しない
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, context) {
    const usecomponent = (use: itemHierarchyType) => {
      const componentName = {
        StoreShelve: 'ExtractStoreShelve',
        Store: 'ExtractStore',
        StoreSingle: 'ExtractStoreSingle',
        Shobunrui: 'ExtractShobunrui',
        Tanpin: 'ExtractItem',
        Chubunrui: 'ExtractChubunrui',
        Category: 'ExtractCategory',
        Class: 'ExtractClass',
        Causal: 'ExtractCausal',
        Vendor: 'ExtractVendor',
        ItemStatus: 'ExtractItemStatus',
      }

      return componentName[use]
    }
    const useWhere = (use: itemHierarchyType) => {
      const wheres = {
        StoreShelve: searchConditionOfStoreShelve.value,
        Store: searchConditionOfStore.value,
        StoreSingle: searchConditionOfStore.value,
        Shobunrui: searchConditionOfShobunrui.value,
        Tanpin: searchConditionOfTanpin.value,
        Chubunrui: searchConditionOfChubunrui.value,
        Class: searchConditionOfClass.value,
        Category: searchConditionOfCategory.value,
        Causal: searchConditionOfCausal.value,
        Vendor: searchConditionOfVendor.value,
        ItemStatus: null,
      }
      return wheres[use]
    }
    const useCols = (use: itemHierarchyType) => {
      const Cols = {
        StoreShelve: 3,
        Store: 3,
        StoreSingle: 3,
        Shobunrui: 3,
        Tanpin: 3,
        Chubunrui: 3,
        Class: 3,
        Category: 3,
        Causal: 3,
        Vendor: 3,
        ItemStatus: 12,
      }
      return Cols[use]
    }
    // 選択されたリスト
    const storeShelve = ref<string[]>([])
    const store = ref<string[]>([])
    const shobunrui = ref<string[]>([])
    const tanpin = ref<string[]>([])
    const chubunrui = ref<string[]>([])
    const category2 = ref<string[]>([])
    const category3 = ref<string[]>([])
    const causal = ref<string[]>([])
    const vendor = ref<string[]>([])
    const itemStatus = ref<string[]>(props.defaultItemsStatus)

    // 選択された数リスト
    const storeShelveCount = ref<number>(0)
    const storeCount = ref<number>(0)
    const shobunruiCount = ref<number>(0)
    const tanpinCount = ref<number>(0)
    const chubunruiCount = ref<number>(0)
    const category2Count = ref<number>(0)
    const category3Count = ref<number>(0)
    const causalCount = ref<number>(0)
    const vendorCount = ref<number>(0)

    // 選択された項目のメタ情報
    const metaInfoOfStoreShelve = ref<Array<UiMStoreShelfEdge>>([])
    const metaInfoOfStore = ref<Array<AllStoreMstEdge>>([])
    const metaInfoOfShobunrui = ref<Array<UiMCategory1Edge>>([])
    const metaInfoOfTanpin = ref<Array<AllSaleitemMstEdge>>([])
    const metaInfoOfChubunrui = ref<Array<UiMCategory0Edge>>([])
    const metaInfoOfClass = ref<Array<UiMCategory2Edge>>([])
    const metaInfoOfCategory = ref<Array<UiMCategory3Edge>>([])
    // 検索されたコードのリスト
    const searchListOfStoreShelve = ref<string[]>([])
    const searchListOfStore = ref<string[]>([])
    const searchListOfShobunrui = ref<string[]>([])
    const searchListOfTanpin = ref<string[]>([])
    const searchListOfChubunrui = ref<string[]>([])
    const searchListOfClass = ref<string[]>([])
    const searchListOfCategory = ref<string[]>([])
    const searchListOfCausal = ref<string[]>([])
    const searchListOfVendor = ref<string[]>([])

    // 検索されたキーワードのリスト
    const searchWordOfStoreShelve = ref<string>('')
    const searchWordOfStore = ref<string>('')
    const searchWordOfShobunrui = ref<string>('')
    const searchWordOfTanpin = ref<string>('')
    const searchWordOfChubunrui = ref<string>('')
    const searchWordOfClass = ref<string>('')
    const searchWordOfCategory = ref<string>('')
    const searchWordOfCausal = ref<string>('')
    const searchWordOfVendor = ref<string>('')

    // 範囲キーワード
    const rangeKeyOfChubunrui = ref<rangeKeys>({ from: '', to: '' })
    const rangeKeyOfShobunrui = ref<rangeKeys>({ from: '', to: '' })
    const rangeKeyOfCategory = ref<rangeKeys>({ from: '', to: '' })
    const rangeKeyOfClass = ref<rangeKeys>({ from: '', to: '' })
    const rangeKeyOfVendor = ref<rangeKeys>({ from: '', to: '' })

    // where条件にわたすリストの整形
    const _cvtWhereList = (array: (Maybe<string> | undefined)[]): string[] => {
      // リストの要素に''があると検索条件に追加されないため、削除している。
      //@ts-ignore
      return _removeList(_uniq(array), ['', null, undefined])
    }
    // 引数１のリストから引数２のリストの要素を削除
    const _removeList = (
      array: (Maybe<string> | undefined)[],
      removeList: any[]
    ) => {
      return array.filter((elem, index, self) => {
        return !removeList.includes(elem)
      })
    }
    // ユニークなリストを返却する
    const _uniq = (array: (Maybe<string> | undefined)[]) => {
      return array.filter((elem, index, self) => {
        return self.indexOf(elem) === index
      })
    }

    /*********************************
     * emit実行する関数
     *********************************/
    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const cond: itemHierarchyGroupExtractCondition = {
        storeShelve: _cvtWhereList(storeShelve.value),
        storeShelveCount: storeShelveCount.value,
        store: _cvtWhereList(store.value),
        storeCount: storeCount.value,
        shobunrui: _cvtWhereList([
          ...shobunrui.value,
          ...metaInfoOfClass.value.map((ele: UiMCategory2Edge) => {
            return ele.node?.category1Cd
          }),
          ...metaInfoOfCategory.value.map((ele: UiMCategory3Edge) => {
            return ele.node?.category1Cd
          }),
        ]),
        shobunruiCount: shobunruiCount.value,
        tanpin: _cvtWhereList(tanpin.value),
        tanpinCount: tanpinCount.value,
        chubunrui: _cvtWhereList(chubunrui.value),
        chubunruiCount: chubunruiCount.value,
        class: _cvtWhereList([
          ...category2.value,
          ...metaInfoOfCategory.value.map((ele: UiMCategory3Edge) => {
            return ele.node?.category2Cd
          }),
        ]),
        classCount: category2Count.value,
        category: _cvtWhereList(category3.value),
        categoryCount: category3Count.value,
        causal: _cvtWhereList(causal.value),
        causalCount: causalCount.value,
        vendor: _cvtWhereList(vendor.value),
        vendorCount: vendorCount.value,
        itemStatus: _cvtWhereList(itemStatus.value),
      }
      // console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }

    // radioボタンのchangeイベント
    const changeRadio = (selected: string) => {
      context.emit('change-radio', selected)
    }

    /*********************************
     * 検索条件
     *********************************/
    const searchConditionOfStoreShelve = computed(() => {
      const WHERE: Where[] = [
        {
          field: ['areaCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['validStores/storeCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['UiMShelfPatterns/saleItem/saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['UiMShelfPatterns/saleItem/category0'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['UiMShelfPatterns/saleItem/category1'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['UiMShelfPatterns/saleItem/category2'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['UiMShelfPatterns/saleItem/category3'],
          op: WhereOp.In,
          value: [''],
        },
        // キーワード検索
        {
          field: ['areaCd', 'areaName'],
          op: WhereOp.Contains,
          value: [''],
        },
        // 固定の条件
        {
          field: ['areaType'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['inactiveFlg'],
          op: WhereOp.Eq,
          value: ['0'],
        },
        // 有効日（開始日）
        {
          field: ['startDate'],
          op: WhereOp.Le,
          value: [''],
        },
        // 有効日（終了日）
        {
          field: ['endDate'],
          op: WhereOp.Ge,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(WHERE))
      setWhereValue(
        where,
        'areaCd',
        _cvtWhereList(searchListOfStoreShelve.value)
      )
      setWhereValue(where, 'validStores/storeCd', _cvtWhereList(store.value))
      setWhereValue(
        where,
        'UiMShelfPatterns/saleItem/saleItemCd',
        _cvtWhereList(tanpin.value)
      )
      setWhereValue(
        where,
        'UiMShelfPatterns/saleItem/category0',
        _cvtWhereList([
          ...chubunrui.value,
          ...metaInfoOfShobunrui.value.map((ele: UiMCategory1Edge) => {
            return ele.node?.parentCategoryCd
          }),
        ])
      )
      setWhereValue(
        where,
        'UiMShelfPatterns/saleItem/category1',
        _cvtWhereList([
          ...shobunrui.value,
          ...metaInfoOfClass.value.map((ele: UiMCategory2Edge) => {
            return ele.node?.category1Cd
          }),
          ...metaInfoOfCategory.value.map((ele: UiMCategory3Edge) => {
            return ele.node?.category1Cd
          }),
        ])
      )
      setWhereValue(
        where,
        'UiMShelfPatterns/saleItem/category2',
        _cvtWhereList([
          ...category2.value,
          ...metaInfoOfCategory.value.map((ele: UiMCategory3Edge) => {
            return ele.node?.category2Cd
          }),
        ])
      )
      setWhereValue(
        where,
        'UiMShelfPatterns/saleItem/category3',
        _cvtWhereList(category3.value)
      )
      let shelfPatternSearchDate = props.validData
      if (!props.areaType.includes('2')) {
        shelfPatternSearchDate = ['', '']
      }
      setWhereValue(where, 'startDate', shelfPatternSearchDate[1])
      setWhereValue(where, 'endDate', shelfPatternSearchDate[0])
      setWhereValue(where, 'areaType', props.areaType)
      // キーワード検索
      setWhereValue(
        where,
        ['areaCd', 'areaName'],
        searchWordOfStoreShelve.value,
        {
          ope: WhereOp.Contains,
        }
      )
      return where
    })

    const searchConditionOfStore = computed(() => {
      const WHERE: Where[] = [
        {
          field: ['storeCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vUiPAreas/areaCd'],
          op: WhereOp.In,
          value: [''],
        },
        // キーワード検索
        {
          field: ['storeCd', 'storeName'],
          op: WhereOp.Contains,
          value: [''],
        },
        // 固定の条件
        {
          field: ['openDate'],
          op: WhereOp.Le,
          value: [''],
        },
        {
          field: ['closeDate'],
          op: WhereOp.Ge,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(WHERE))
      setWhereValue(where, 'storeCd', _cvtWhereList(searchListOfStore.value))
      setWhereValue(where, 'vUiPAreas/areaCd', _cvtWhereList(storeShelve.value))
      // キーワード検索
      setWhereValue(where, ['storeCd', 'storeName'], searchWordOfStore.value, {
        ope: WhereOp.Contains,
      })
      // 検索日付で閉店している店舗は除外
      setWhereValue(where, 'openDate', props.validData[1])
      setWhereValue(where, 'closeDate', props.validData[0])
      return where
    })

    const searchConditionOfShobunrui = computed(() => {
      const WHERE: Where[] = [
        {
          field: ['parentCategoryCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category1Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category1Cd'],
          op: WhereOp.Ge,
          value: [''],
        },
        {
          field: ['category1Cd'],
          op: WhereOp.Le,
          value: [''],
        },
        // キーワード検索
        {
          field: ['category1Cd', 'categoryName'],
          op: WhereOp.Contains,
          value: [''],
        },
        {
          field: ['saleItems/saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(WHERE))
      const cat1List = metaInfoOfTanpin.value.map((ele: AllSaleitemMstEdge) => {
        return ele.node?.category1
      })

      setWhereValue(where, 'parentCategoryCd', _cvtWhereList(chubunrui.value), {
        ope: WhereOp.In,
      })
      setWhereValue(
        where,
        'category1Cd',
        _cvtWhereList([
          ...metaInfoOfCategory.value.map((ele: UiMCategory3Edge) => {
            return ele.node?.category1Cd
          }),
          ...metaInfoOfClass.value.map((ele: UiMCategory2Edge) => {
            return ele.node?.category1Cd
          }),
          ...searchListOfShobunrui.value,
        ]),
        {
          ope: WhereOp.In,
        }
      )
      setWhereValue(where, 'category1Cd', rangeKeyOfShobunrui.value.from, {
        ope: WhereOp.Ge,
      })
      setWhereValue(where, 'category1Cd', rangeKeyOfShobunrui.value.to, {
        ope: WhereOp.Le,
      })
      // キーワード検索
      setWhereValue(
        where,
        ['category1Cd', 'categoryName'],
        searchWordOfShobunrui.value,
        {
          ope: WhereOp.Contains,
        }
      )
      setWhereValue(where, 'saleItems/saleItemCd', _cvtWhereList(tanpin.value))
      return where
    })

    const searchConditionOfTanpin = computed(() => {
      const WHERE: Where[] = [
        {
          field: ['category1'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['uiMCategory0/category0Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category2'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category3'],
          op: WhereOp.In,
          value: [''],
        },
        // 棚パターン
        {
          field: ['UiMShelfPatterns/shelfPatternCd'],
          op: WhereOp.In,
          value: [''],
        },
        // キーワード検索
        {
          field: ['saleItemCd', 'saleItemName'],
          op: WhereOp.Contains,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(WHERE))
      setWhereValue(
        where,
        'uiMCategory0/category0Cd',
        _cvtWhereList([
          ...chubunrui.value,
          ...metaInfoOfShobunrui.value.map((ele: UiMCategory1Edge) => {
            return ele.node?.parentCategoryCd
          }),
        ])
      ),
        setWhereValue(
          where,
          'category1',
          _cvtWhereList([
            ...shobunrui.value,
            ...metaInfoOfClass.value.map((ele: UiMCategory2Edge) => {
              return ele.node?.category1Cd
            }),
            ...metaInfoOfCategory.value.map((ele: UiMCategory3Edge) => {
              return ele.node?.category1Cd
            }),
          ])
        ),
        setWhereValue(
          where,
          'category2',
          _cvtWhereList([
            ...category2.value,
            ...metaInfoOfCategory.value.map((ele: UiMCategory3Edge) => {
              return ele.node?.category2Cd
            }),
          ])
        )
      setWhereValue(where, 'category3', _cvtWhereList(category3.value))
      setWhereValue(
        where,
        'saleItemCd',
        _cvtWhereList(searchListOfTanpin.value)
      )
      setWhereValue(
        where,
        'UiMShelfPatterns/shelfPatternCd',
        _cvtWhereList(storeShelve.value)
      )
      // キーワード検索
      setWhereValue(
        where,
        ['saleItemCd', 'saleItemName'],
        searchWordOfTanpin.value,
        {
          ope: WhereOp.Contains,
        }
      )
      return where
    })

    const searchConditionOfChubunrui = computed(() => {
      const WHERE: Where[] = [
        {
          field: ['category0Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category0Cd', 'categoryName'],
          op: WhereOp.Contains,
          value: [''],
        },
        {
          field: ['category0Cd'],
          op: WhereOp.Ge,
          value: [''],
        },
        {
          field: ['category0Cd'],
          op: WhereOp.Le,
          value: [''],
        },
        {
          field: ['saleItems/saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(WHERE))
      // const cat0List = metaInfoOfChubunrui.value.map(
      //   (ele: UiMCategory0Edge) => {
      //     return ele.node?.category0Cd
      //   }
      // )
      // キーワード検索
      setWhereValue(
        where,
        ['category0Cd', 'categoryName'],
        searchWordOfChubunrui.value,
        {
          ope: WhereOp.Contains,
        }
      )
      setWhereValue(
        where,
        'category0Cd',
        _cvtWhereList([
          ...metaInfoOfShobunrui.value.map((ele: UiMCategory1Edge) => {
            return ele.node?.parentCategoryCd
          }),
          ...searchListOfChubunrui.value,
        ]),
        {
          ope: WhereOp.In,
        }
      )
      setWhereValue(where, 'category0Cd', rangeKeyOfChubunrui.value.from, {
        ope: WhereOp.Ge,
      })
      setWhereValue(where, 'category0Cd', rangeKeyOfChubunrui.value.to, {
        ope: WhereOp.Le,
      })
      setWhereValue(where, 'saleItems/saleItemCd', _cvtWhereList(tanpin.value))
      // console.log('searchConditionOfChubunrui', { cat0List, where })

      return where
    })
    const searchConditionOfClass = computed(() => {
      const WHERE: Where[] = [
        {
          field: ['uiMCategory0/category0Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category1Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category2Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category2Cd'],
          op: WhereOp.Ge,
          value: [''],
        },
        {
          field: ['category2Cd'],
          op: WhereOp.Le,
          value: [''],
        },
        {
          field: ['category2Cd', 'categoryName'],
          op: WhereOp.Contains,
          value: [''],
        },
        {
          field: ['saleItems/saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(WHERE))
      // const cat1List = metaInfoOfTanpin.value
      //   .map((ele: AllSaleitemMstEdge) => {
      //     return ele.node?.category1
      //   })
      //   .concat(shobunrui.value)
      const cat2List = metaInfoOfTanpin.value
        .map((ele: AllSaleitemMstEdge) => {
          return ele.node?.category2
        })
        .concat(searchListOfClass.value)

      setWhereValue(
        where,
        'uiMCategory0/category0Cd',
        _cvtWhereList(chubunrui.value)
      )
      setWhereValue(
        where,
        'category1Cd',
        _cvtWhereList([
          ...shobunrui.value,
          ...metaInfoOfCategory.value.map((ele: UiMCategory3Edge) => {
            return ele.node?.category1Cd
          }),
        ])
      ),
        setWhereValue(
          where,
          'category2Cd',
          _cvtWhereList([
            ...metaInfoOfCategory.value.map((ele: UiMCategory3Edge) => {
              return ele.node?.category2Cd
            }),
            ...searchListOfClass.value,
          ]),
          {
            ope: WhereOp.In,
          }
        )
      setWhereValue(where, 'category2Cd', rangeKeyOfClass.value.from, {
        ope: WhereOp.Ge,
      })
      setWhereValue(where, 'category2Cd', rangeKeyOfClass.value.to, {
        ope: WhereOp.Le,
      })
      // キーワード検索
      setWhereValue(
        where,
        ['category2Cd', 'categoryName'],
        searchWordOfClass.value,
        {
          ope: WhereOp.Contains,
        }
      )
      setWhereValue(where, 'saleItems/saleItemCd', _cvtWhereList(tanpin.value))
      return where
    })
    const searchConditionOfCategory = computed(() => {
      const WHERE: Where[] = [
        {
          field: ['uiMCategory0/category0Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category1Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category2Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category3Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category3Cd'],
          op: WhereOp.Ge,
          value: [''],
        },
        {
          field: ['category3Cd'],
          op: WhereOp.Le,
          value: [''],
        },
        {
          field: ['category3Cd', 'categoryName'],
          op: WhereOp.Contains,
          value: [''],
        },
        {
          field: ['saleItems/saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(WHERE))
      const cat1List = metaInfoOfTanpin.value
        .map((ele: AllSaleitemMstEdge) => {
          return ele.node?.category1
        })
        .concat(shobunrui.value)
      const cat2List = metaInfoOfTanpin.value
        .map((ele: AllSaleitemMstEdge) => {
          return ele.node?.category2
        })
        .concat(category2.value)
      const cat3List = metaInfoOfTanpin.value
        .map((ele: AllSaleitemMstEdge) => {
          return ele.node?.category3
        })
        .concat(searchListOfCategory.value)
      setWhereValue(
        where,
        'uiMCategory0/category0Cd',
        _cvtWhereList(chubunrui.value)
      )
      setWhereValue(
        where,
        'category1Cd',
        _cvtWhereList([
          ...shobunrui.value,
          ...metaInfoOfClass.value.map((ele: UiMCategory2Edge) => {
            return ele.node?.category1Cd
          }),
        ])
      )
      setWhereValue(where, 'category2Cd', _cvtWhereList(cat2List))
      setWhereValue(where, 'category3Cd', _cvtWhereList(cat3List), {
        ope: WhereOp.In,
      })
      setWhereValue(where, 'category3Cd', rangeKeyOfCategory.value.from, {
        ope: WhereOp.Ge,
      })
      setWhereValue(where, 'category3Cd', rangeKeyOfCategory.value.to, {
        ope: WhereOp.Le,
      })
      // キーワード検索
      setWhereValue(
        where,
        ['category3Cd', 'categoryName'],
        searchWordOfCategory.value,
        {
          ope: WhereOp.Contains,
        }
      )
      setWhereValue(where, 'saleItems/saleItemCd', _cvtWhereList(tanpin.value))
      return where
    })

    const searchConditionOfCausal = computed(() => {
      const WHERE: Where[] = [
        {
          field: ['causalCd'],
          op: WhereOp.In,
          value: [''],
        },
        // 固定の条件
        {
          field: ['inactiveFlg'],
          op: WhereOp.Eq,
          value: ['0'],
        },
        // キーワード検索
        {
          field: ['causalCd', 'causalName'],
          op: WhereOp.Contains,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(WHERE))
      setWhereValue(where, 'causalCd', _uniq(searchListOfCausal.value))
      // キーワード検索
      setWhereValue(
        where,
        ['causalCd', 'causalName'],
        searchWordOfCausal.value,
        {
          ope: WhereOp.Contains,
        }
      )
      return where
    })

    const searchConditionOfVendor = computed(() => {
      const WHERE: Where[] = [
        {
          field: ['vendorCd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['vendorCd'],
          op: WhereOp.Ge,
          value: [''],
        },
        {
          field: ['vendorCd'],
          op: WhereOp.Le,
          value: [''],
        },
        {
          field: ['vendorCd', 'vendorName'],
          op: WhereOp.Contains,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(WHERE))
      setWhereValue(
        where,
        'vendorCd',
        _cvtWhereList(searchListOfVendor.value),
        {
          ope: WhereOp.In,
        }
      )
      setWhereValue(where, 'vendorCd', rangeKeyOfVendor.value.from, {
        ope: WhereOp.Ge,
      })
      setWhereValue(where, 'vendorCd', rangeKeyOfVendor.value.to, {
        ope: WhereOp.Le,
      })
      // キーワード検索
      setWhereValue(
        where,
        ['vendorCd', 'vendorName'],
        searchWordOfVendor.value,
        {
          ope: WhereOp.Contains,
        }
      )
      console.log(where)
      return where
    })

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/

    const changeSelected = (params: emitHierarchySelect) => {
      // console.log('changeSelected', { params })
      switch (params.itemHierarchyType) {
        case 'StoreShelve':
          storeShelve.value = params.selected
          storeShelveCount.value = params.selectedCount
          metaInfoOfStoreShelve.value = params.setectedMetaInfo
          break
        case 'Store':
        case 'StoreSingle':
          store.value = params.selected
          storeCount.value = params.selectedCount
          metaInfoOfStore.value = params.setectedMetaInfo
          break
        case 'Shobunrui':
          shobunrui.value = params.selected
          shobunruiCount.value = params.selectedCount
          metaInfoOfShobunrui.value = params.setectedMetaInfo
          break
        case 'Tanpin':
          tanpin.value = params.selected
          tanpinCount.value = params.selectedCount
          metaInfoOfTanpin.value = params.setectedMetaInfo
          break
        case 'Chubunrui':
          chubunrui.value = params.selected
          chubunruiCount.value = params.selectedCount
          metaInfoOfChubunrui.value = params.setectedMetaInfo
          break
        case 'Class':
          category2.value = params.selected
          category2Count.value = params.selectedCount
          metaInfoOfClass.value = params.setectedMetaInfo
          break
        case 'Category':
          category3.value = params.selected
          category3Count.value = params.selectedCount
          metaInfoOfCategory.value = params.setectedMetaInfo
          break
        case 'Causal':
          causal.value = params.selected
          causalCount.value = params.selectedCount
          break
        case 'Vendor':
          vendor.value = params.selected
          vendorCount.value = params.selectedCount
          break
        case 'ItemStatus':
          itemStatus.value = params.selected
          break
        default:
          break
      }
      changeExtractCondition()
    }

    const onSearch = (params: emitHierarchySearch) => {
      // console.log('onSearch', { params })
      switch (params.itemHierarchyType) {
        case 'StoreShelve':
          searchListOfStoreShelve.value = params.searchList
          break
        case 'Store':
        case 'StoreSingle':
          searchListOfStore.value = params.searchList
          break
        case 'Shobunrui':
          searchListOfShobunrui.value = params.searchList
          break
        case 'Tanpin':
          searchListOfTanpin.value = params.searchList
          break
        case 'Chubunrui':
          searchListOfChubunrui.value = params.searchList
          break
        case 'Class':
          searchListOfClass.value = params.searchList
          break
        case 'Category':
          searchListOfCategory.value = params.searchList
          break
        case 'Causal':
          searchListOfCausal.value = params.searchList
          break
        case 'Vendor':
          searchListOfVendor.value = params.searchList
          break
        case 'ItemStatus':
          // do nothing
          break
        default:
          break
      }
    }

    const onSearchWord = (params: emitHierarchySearch) => {
      // console.log('onSearch', { params })
      switch (params.itemHierarchyType) {
        case 'StoreShelve':
          searchWordOfStoreShelve.value = params.searchWord
          break
        case 'Store':
        case 'StoreSingle':
          searchWordOfStore.value = params.searchWord
          break
        case 'Shobunrui':
          searchWordOfShobunrui.value = params.searchWord
          break
        case 'Tanpin':
          searchWordOfTanpin.value = params.searchWord
          break
        case 'Chubunrui':
          searchWordOfChubunrui.value = params.searchWord
          break
        case 'Class':
          searchWordOfClass.value = params.searchWord
          break
        case 'Category':
          searchWordOfCategory.value = params.searchWord
          break
        case 'Causal':
          searchWordOfCausal.value = params.searchWord
          break
        case 'Vendor':
          searchWordOfVendor.value = params.searchWord
          break
        case 'ItemStatus':
          // do nothing
          break
        default:
          break
      }
    }

    const changeRangeKey = (params: emitHierarchyRangeKeys) => {
      console.log('changeRangeKey', params)
      switch (params.itemHierarchyType) {
        case 'StoreShelve':
          // do nothing
          break
        case 'Store':
          // do nothing
          break
        case 'Shobunrui':
          rangeKeyOfShobunrui.value = params.rangeKeys
          break
        case 'Tanpin':
          // do nothing
          break
        case 'Chubunrui':
          rangeKeyOfChubunrui.value = params.rangeKeys
          break
        case 'Class':
          rangeKeyOfClass.value = params.rangeKeys
          break
        case 'Category':
          rangeKeyOfCategory.value = params.rangeKeys
          break
        case 'Vendor':
          rangeKeyOfVendor.value = params.rangeKeys
          break
        case 'ItemStatus':
          // do nothing
          break
        default:
          break
      }
    }

    return {
      usecomponent,
      useWhere,
      useCols,
      changeSelected,
      changeRadio,
      onSearch,
      onSearchWord,
      changeRangeKey,
    }
  },
})
