






import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  components: {},
  props: {
    cols: {
      type: Number as PropType<number>,
      default: 3,
    },
  },
  setup(_, context) {
    return {}
  },
})
